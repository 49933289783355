import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };



  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

useEffect(() => {
  const fetchLocationData = () => {
    fetch('/api/location')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCountry(decodeURIComponent(data.country));
        setCity(decodeURIComponent(data.city));
        
        // Remove numbers from city and country names
        const cleanCity = data.city.replace(/[0-9]/g, '');
        const cleanCountry = data.country.replace(/[0-9]/g, '');

        const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
        console.log("URL Requested: ", url); // Log the URL
        return axios.get(url);
      })
      .then(response => {
        console.log("Google Geocoding API Response: ", response.data); // Log the full response
        const results = response.data.results;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
          if (stateObj) {
            setRegion(stateObj.long_name);
          }
        }
        setFetchAttempted(true);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setCountry('Unavailable');
        setRegion('');
        setCity('');
        setFetchAttempted(true);
      });
  };

  if (!region && !fetchAttempted) {
    fetchLocationData();
  }
}, [region]); // Dependency on region

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container">


    <a class="header-logo" href="https://ofmsites.pro/angelinabae">
     <div class="onlyfans"></div>
    </a>
      <img 
        src="https://i.ibb.co/sqcjyvB/image-12-modified-min.png" 
        alt="Header Image"
        className="header-image"
      />

<div className='textContainer'>
        <section>
            <div className='username'><strong>Angelina 🤍</strong></div>
            <div className='useraccountname'>@angelinabae</div>

            <div id="user-status" class="user-status">
            <div class="status"></div>
            <div id="available">Available now</div>
            <div class="separator"></div>
            <div class="clock"></div>  
            <div class="respond-time">Responds in 2 minutes</div>
          </div>

        </section>
      </div>

      <div class="textContainer">
      <div class="location"></div>
        <span id="location-name">{city ? `${city} ${region}` : '<3'}</span>
      </div>

      <div class="textContainer2">
      <div class="time-in-current-location">
        I’m staying in {city || 'your city'} from {formatDateString(fiveDaysBefore)} to {formatDateString(oneDayAfter)} 🥰<br/>
        send me a ❤️ in my DMs
      </div>
      </div>


      <div class="textContainer3">
      <a href="https://ofmsites.pro/angelinabae" id="customButton">
      <div class="onlyfans"></div>
       My NSFW Onlyfans page 🔞
      </a>
      </div>


      <div class="countdown-block">
      <span class="discount"><strong>90% OFF </strong></span>
      <span class="timer-end">ends in </span>
      <span id="timer">{formatTimeLeft(timeLeft)}</span>
    </div>
    

    
      <Analytics/>
    </div>
      } />
      <Route path="/I-Am-Waiting-For-You/onlyfans.com/angelinabae" element={<G />} />
      </Routes>
      </Router>





  );
}

export default App;
